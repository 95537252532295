import './App.css';
import { useState, useEffect } from 'react';
import { MyAccount, Config, store } from '@cleeng/mediastore-sdk';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import HomePage from './pages/HomePage/HomePage';
import HomePageFr from './pages/HomePageFr/HomePageFr';
import Components from './pages/Components/Components';
import AuthContext from './components/AuthContext/AuthContext';
import Header from './components/Header/Header';
import { Popups } from './components/Popups/Popups';
import { Helmet } from 'react-helmet';
import Carousel from "./components/Carousel";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  useEffect(() => {
    Config.setEnvironment('production');
    Config.setPublisher('795458024');   
    Config.setOffer('S874998139_US');
   
    
    Config.setMyAccountUrl('https://www.whiteboxplay.com/thank-you-merci');
    Config.setAdyenConfig({
      clientKey: "live_BQDOFBYTGZB3XKF62GBYSLPUJ4YW2TPL",
      adyenEnv: "live"
    });
    // needed for Checkout Paypal payments
Config.setPaypalUrls({
  successUrl: "https://www.whiteboxplay.com/thank-you-merci",
  cancelUrl: "https://www.whiteboxplay.com/",
  errorUrl: "https://www.whiteboxplay.com/error"
});
    Config.setTheme(
      {
        "fontColor": "#ffffff",
        "backgroundColor": "#292525",
        "cardColor": "#675d5d",
        "successColor": "#435dc5",
        "primaryColor": "#435dc5",
        "loaderColor": "#cccccc",
        "errorColor": "red",
        "logoUrl": "https://www.whiteboxplay.com/images/whitebox/WBP-Blanc-sur-noir-no-background-01-01.png"
      }
    )
  },[]);
  
  return (

    <Provider store={store}>
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
          <div className="App">  
          
          <Helmet>
            <title>Whitebox Play | LIVE CONCERT - STREAMING - EXCLUSIVE</title>
            <meta name="description" content="WhiteBox Play - Live concert, Streaming and more!" />            
          </Helmet>        
          
            <Router>
              <main>
                <Popups>
                  <Switch>
                    <Route 
                      path="/"
                      exact
                      component={() => <HomePage />}
                    />
                    <Route 
                      path="/fr"
                      exact
                      component={() => <HomePageFr />}
                    />                                                                                            
                  </Switch>
                </Popups>
              </main>
            </Router>
          </div>
      </AuthContext.Provider>
    </Provider>
  );
}

export default App;
