import Auth from "../../Auth"
import logo from './images/WBP-Blanc-sur-noir-no-background-01-01.png';

import {
  HeaderStyled,
  LogoStyled
} from './HeaderStyled';

const Header = ({ color }) => {
  return (
    <HeaderStyled id="header" color={color}>
      <a id="backToHomeURL" href="/">
      <LogoStyled className="logoHome" src={logo} />
      </a>
      <Auth/>
    </HeaderStyled>
  )
}

export default Header;