import { useEffect, useContext } from 'react';
import './App.css';
import { Auth as MediastoreAuth } from '@cleeng/mediastore-sdk';
import AuthContext from './components/AuthContext/AuthContext';
import { NavLink } from 'react-router-dom';
import { usePopupsContext, popupTypes } from './components/Popups/Popups';



const Auth = () => {
  const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
  const {showModal} = usePopupsContext();

  useEffect(() => {
    if (MediastoreAuth.isLogged()) {
      setIsAuthenticated(true);
    }
  },)
 
  return (
      <div className="AppAuth">
        <div className="App-header">
          <ul className="menu">
            
            { !isAuthenticated && (
              <>                
                <li className="btn-style"><a className="button-web-app" id="webapplink" href="https://app.whiteboxplay.com"><span className="appTextSignIn">SIGN UP TO WHITEBOX PLAY</span></a></li>
                <li className="mobile-li btn-sign-in"><a className="button-web-app-sign" id="webapplinksignup" href="https://app.whiteboxplay.com">Sign in</a></li>
                <li className="mobile-li btn-lang"><a id="langSelector" className="lang" href="/fr">FR</a></li>
              </>
            )}
            { isAuthenticated && (
              <>                
                <li><a className="button-web-app" id="webapplink" href="https://app.whiteboxplay.com"><span className="appText">Watch</span><i className="fa-solid fa-circle-play"></i></a></li>
                <li><button onClick={() => {MediastoreAuth.logout(false, '', setIsAuthenticated)}}><span className="appTextLogout">Sign out</span></button></li>
                <li><a id="langSelector" className="lang" href="/fr">FR</a></li>
              </>
            )}
          </ul>
        </div>
      </div>
  );
}

export default Auth;
